import Form from '@searchtrends/webform-processor';

require('leaflet');

window.hideGoogleMaps = function () {
    document.cookie = "showGoogleMaps=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict";

    location.reload();
}

window.allowGoogleMaps = function () {
    document.cookie = "showGoogleMaps=" + Math.floor(Date.now() / 1000) + "; path=/; SameSite=Strict";
}

window.loadMapsJs = function () {
    initMap();
}

let contactforms = {};
let forms = document.querySelectorAll('form[method="post"]:not(.no-ajax)');
forms.forEach(function (cf, index) {
    contactforms[`sendform_${index}`] = new Form(cf, index).onlyOnce();

    cf.addEventListener('submit', function (e) {
        e.preventDefault();

        contactforms[`sendform_${index}`].post(cf.action)
            .then(response => { console.log(response); });
    });
});

if (document.querySelector('.mobile-menu')) {
    document.querySelector('.mobile-menu .closed').addEventListener('click', function (e) {
        document.querySelector('.mobile-menu .closed').classList.add('hidden');
        document.querySelector('.mobile-menu .open').classList.remove('hidden');
        document.querySelector('header nav .menu').style.display = 'flex';
    });
    document.querySelector('.mobile-menu .open').addEventListener('click', function (e) {
        document.querySelector('.mobile-menu .open').classList.add('hidden');
        document.querySelector('.mobile-menu .closed').classList.remove('hidden');
        document.querySelector('header nav .menu').style.display = 'none';
    });
}

window.axios = require('axios');

document.querySelector('#products_menu').addEventListener('mouseover', function() {
    document.querySelector('#products_submenu').classList.remove('hidden');
});
document.querySelector('#products_menu').addEventListener('mouseout', function () {
    document.querySelector('#products_submenu').classList.add('hidden');
});
